<template>
	<div class="center paddt20 bg-F7F7F7">
		<div class="w1200 bg-white">
			<div class="padd20">
				<div class=" justify-content-center-between">
					<div>
						<div class="fz16 co-333333">评估结果</div>
						<div class="fz12 co-999999 mart6">2021.01.01 15:21</div>
					</div>
					<div class="align-items-center cure-pointer">
						<!-- <img src="@/assets/img/pdf_icon.png" alt="" />
						<span class="fz12 co-333333 display-block marl12">评估报告.docx</span> -->
						<div class="fz14 co-010085 center marl52 btn-down" @click="download(info.assess_result,'评估报告')">附件下载
						</div>
					</div>
				</div>

				<div class="mart20">
					<img class="display-block main-pic" :src="info.assess_result" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		useRoute
	} from 'vue-router';
	export default {
		name: 'AssessmentDetail',
		data() {
			return {
				info: ''

			}
		},
		mounted() {
			let data = useRoute().query;
			this.$req({
				method: 'get',
				url: '/api/works/detail',
				data: {
					id: data.id
				},
				success: res => {
					console.log(res)
					this.info = res
				},
				fail: error => {}
			});
			console.log(data)
		},
		methods: {
			download(url,fileName) {
				fetch(url).then(res => res.blob()).then(blob => {
					let eleLink = document.createElement("a");
					eleLink.style.display = "none";
					eleLink.target = "_blank";
					eleLink.setAttribute('download', fileName);
					eleLink.href = URL.createObjectURL(blob);
					document.body.appendChild(eleLink);
					eleLink.click();
					URL.revokeObjectURL(eleLink.href);
					document.body.removeChild(eleLink);
				})

			}
		}
	}
</script>

<style lang="less" scoped>
	.pdf_icon {
		width: 25.7px;
		height: 29.99px;
	}

	.main-pic {
		width: 1160px;
		height: 709px;
	}

	.btn-down {
		width: 80px;
		height: 30px;
		background: #DCFF03;
		border-radius: 27px;
	}
</style>
